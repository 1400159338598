import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BookkeepingCustomer, BookkeepingCustomerListCombinedBillingCustomerForEAgreementInternalRequest, ClientTransferTarget, DiscountGroup, EAgreementUpdateRequest, MyHttpApi, PrivilegeItem, Product, SalesChannel, SalesRestriction } from 'utils/api';
import { getPrivilege, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

@autoinject
export class EAgreementsEdit {
  private eAgreement: EAgreementUpdateRequest = {
    name: '',
    validityStart: new Date(),
    delete: false,
    type: 'CLIENT',
    privilegeId: 0,
    productIdList: [],
    bookkeepingCustomerIdList: [],
  };

  private bookkeepingCustomerList: BookkeepingCustomer[] = [];
  private bookkeepingCustomerMap: { [key: number]: BookkeepingCustomer; } = {};
  private discountGroupList: DiscountGroup[] = [];
  private extraClientTransferTarget?: ClientTransferTarget;
  private extraDiscountGroup?: DiscountGroup;
  private extraSalesChannel?: SalesChannel;
  private extraSalesRestriction?: SalesRestriction;
  private privilege?: PrivilegeItem;
  private clientTransferTargetList: ClientTransferTarget[] = [];
  private productList: Product[] = [];
  private salesChannelList: SalesChannel[] = [];
  private salesRestrictionList: SalesRestriction[] = [];
  private selectedProductList: Product[] = [];
  private selectedBookkeepingCustomerIdList: number[] = [];
  private typeAndName = "";
  private newBookkeepingCustomer?: BookkeepingCustomer;
  private editingBookkeepingCustomerId?: number;
  private newEAgreementBusinessExpenseUnit = "";
  canEdit = true;

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N, private readonly notify: Notify) {
  }

  async activate(params: { id?: string; }) {
    this.privilege = getPrivilege();
    if (!this.privilege) {
      return;
    }

    const [discountGroupList, productList, salesChannelList, salesRestrictionList] = await Promise.all([
      this.api.discountGroupList({ id: this.privilege?.id, type: this.privilege.type }),
      this.api.productList({ id: this.privilege?.id, type: this.privilege.type }),
      this.api.salesChannelList({ id: this.privilege?.id, type: this.privilege.type }),
      this.api.salesRestrictionList({ id: this.privilege?.id, type: this.privilege.type }),
    ]);

    switch (this.privilege.type) {
      case "CLIENT":
        this.clientTransferTargetList = await this.api.clientTransferTargetListSelectable({ clientId: this.privilege.id });
        break;
      case "BUSINESS_GROUP":
        this.clientTransferTargetList = await this.api.clientTransferTargetListSelectable({ businessGroupId: this.privilege.id });
        break;
      default:
        this.notify.info("Corporation privilege not supported");
        this.router.navigateBack();
        return;
    }

    this.discountGroupList = discountGroupList.filter(x => !x.deleteTime);
    this.productList = productList.filter(x => !x.deleteTime);
    this.salesChannelList = salesChannelList.filter(x => !x.deleteTime);
    this.salesRestrictionList = salesRestrictionList.filter(x => !x.deleteTime);

    if (params.id) {
      const id = parseInt(params.id);
      const [eAgreement, selectedProductIdList, selectedBookkeepingCustomerIdList] = await Promise.all([
        this.api.eAgreementById({ id }),
        this.api.eAgreementListProductIdsByEAgreementId({ eAgreementId: id }),
        this.api.eAgreementListBookkeepingCustomerIdsByEAgreementId({ eAgreementId: id }),
      ]);

      this.eAgreement = {
        ...eAgreement,
        delete: !!eAgreement.deleteTime,
        type: this.privilege.type,
        privilegeId: this.privilege.id,
        productIdList: [],
        bookkeepingCustomerIdList: [],
      };

      // Updates bookkeepingCustomerList
      await this.searchCustomers();

      const productMap = MyHttpApi.toHashStr(productList);
      this.selectedProductList = selectedProductIdList.map(productId => productMap[productId]);
      // Filter out obsolete customer entries
      this.selectedBookkeepingCustomerIdList = selectedBookkeepingCustomerIdList.filter(bookkeepingCustomerId => bookkeepingCustomerId in this.bookkeepingCustomerMap);

      this.extraClientTransferTarget = this.clientTransferTargetList.find(x => x.id === eAgreement.clientTransferTargetId);
      this.extraDiscountGroup = discountGroupList.find(x => x.id === eAgreement.discountGroupId);
      this.extraSalesChannel = salesChannelList.find(x => x.id === eAgreement.salesChannelId);
      this.extraSalesRestriction = salesRestrictionList.find(x => x.id === eAgreement.salesRestrictionId);

      this.privilege = privilegeItemFromElement(eAgreement);
    }
    if (this.privilege) {
      this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
      this.canEdit = await this.api.privilegeCanEdit(this.privilege);
    }
  }

  async searchCustomers() {
    if (!this.eAgreement.clientTransferTargetId) {
      return [];
    }
    const clientId = this.privilege?.type == "CLIENT" && this.privilege.id ? this.privilege.id : undefined;
    const req: BookkeepingCustomerListCombinedBillingCustomerForEAgreementInternalRequest = {
      targetId: this.eAgreement.clientTransferTargetId,
      clientId,
    };
    this.bookkeepingCustomerList = await this.api.bookkeepingCustomerListCombinedBillingCustomerForEAgreement(req);
    this.bookkeepingCustomerMap = MyHttpApi.toHash(this.bookkeepingCustomerList);
  }

  @computedFrom("eAgreement.clientTransferTargetId")
  get clientTransferTargetIdListener() {
    void this.searchCustomers();
    return "";
  }

  @computedFrom("editingBookkeepingCustomerId")
    get editingBookkeepingCustomerIdListener() {
    this.newEAgreementBusinessExpenseUnit = "";
    return "";
  }

  @computedFrom("bookkeepingCustomerList", "selectedBookkeepingCustomerIdList.length")
    get bookkeepingCustomerListDynamic() {
    return this.bookkeepingCustomerList.filter(x => !this.selectedBookkeepingCustomerIdList.includes(x.id));
  }

  async save(deleted: boolean) {
    if (!this.privilege) {
      return;
    }
    this.addBusinessExpenseUnit();
    await this.api.eAgreementUpdate({
      ...this.eAgreement,
      productIdList: this.selectedProductList.map(x => x.id),
      bookkeepingCustomerIdList: this.selectedBookkeepingCustomerIdList,
      privilegeId: this.privilege.id,
      type: this.privilege.type,
      delete: deleted,
    });
    this.router.navigateBack();
  }

  async editBookkeepingCustomerDone() {
    if (!this.editingBookkeepingCustomerId) {
      return;
    }
    const bookkeepingCustomer = this.bookkeepingCustomerMap[this.editingBookkeepingCustomerId];

    await this.api.bookkeepingCustomerUpdateEAgreementData({
      bookkeepingCustomerId: this.editingBookkeepingCustomerId,
      eAgreementBusinessId: bookkeepingCustomer.eAgreementBusinessId,
      eAgreementBusinessExpenseUnits: bookkeepingCustomer.eAgreementBusinessExpenseUnits,
    });
    this.editingBookkeepingCustomerId = undefined;
  }

  addBookkeepingCustomer() {
    if (!this.newBookkeepingCustomer) {
      return;
    }
    this.selectedBookkeepingCustomerIdList.push(this.newBookkeepingCustomer.id);
    this.editingBookkeepingCustomerId = this.newBookkeepingCustomer.id;
    this.newBookkeepingCustomer = undefined;
  }

  removeBookkeepingCustomer(bookkeepingCustomerId: number) {
    this.selectedBookkeepingCustomerIdList = this.selectedBookkeepingCustomerIdList.filter(x => x != bookkeepingCustomerId);
  }

  editBookkeepingCustomerStart(bookkeepingCustomerId: number) {
    this.editingBookkeepingCustomerId = bookkeepingCustomerId;
  }

  addBusinessExpenseUnit() {
    if (!this.editingBookkeepingCustomerId || !this.newEAgreementBusinessExpenseUnit) {
      return;
    }
    this.bookkeepingCustomerMap[this.editingBookkeepingCustomerId].eAgreementBusinessExpenseUnits.push(this.newEAgreementBusinessExpenseUnit);
    this.bookkeepingCustomerMap[this.editingBookkeepingCustomerId].eAgreementBusinessExpenseUnits = Array.from(new Set(this.bookkeepingCustomerMap[this.editingBookkeepingCustomerId].eAgreementBusinessExpenseUnits)); // Get unique values
    this.newEAgreementBusinessExpenseUnit = "";
  }

  removeBusinessExpenseUnit(eAgreementBusinessExpenseUnit: string) {
    if (!this.editingBookkeepingCustomerId) {
      return;
    }
    this.bookkeepingCustomerMap[this.editingBookkeepingCustomerId].eAgreementBusinessExpenseUnits = this.bookkeepingCustomerMap[this.editingBookkeepingCustomerId].eAgreementBusinessExpenseUnits.filter(x => x != eAgreementBusinessExpenseUnit);
  }

  listBookkeepingBusinessExpenseUnits(bookkeepingCustomerId: number) {
    return this.bookkeepingCustomerMap[bookkeepingCustomerId].eAgreementBusinessExpenseUnits.join(", ");
  }
}
