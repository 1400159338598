import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, Client, EAgreement, MyHttpApi, PrivilegeItem } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedCorporation } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

interface UIEAgreement extends EAgreement {
	deleted: boolean;
}

@autoinject
export class EAgreementsList {
	private fieldList: FieldSpec[] = [];
	private readonly defaults: { [key: string]: string; } = { "deleted": " " };
	private itemList: UIEAgreement[] = [];
	private privilege?: PrivilegeItem;
	private clients: { [key: number]: Client; } = {};
	private businessGroups: { [key: number]: BusinessGroup; } = {};

	constructor(private readonly notify: Notify, private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N) {
	}

	async activate() {
		let { clients, businessGroups } = await getAllAccessLevelsViaClient(this.api);
		this.clients = clients;
		this.businessGroups = businessGroups;

		this.privilege = getSelectedCorporation();
		this.fieldList = [
			{ key: "name", header: "common.name", type: "text", },
			{ key: "validityStart", header: "auditMemo.startDate", type: "date", format:"DD.MM.YYYY" },
			{ key: "validityEnd", header: "auditMemo.endDate", type: "date", format: "DD.MM.YYYY" },
			{ key: "deleted", header: "common.deleted", type: "boolean", },
			{ key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: businessGroups, lookupKey: "name", },
			{ key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
		];
	}

	async rowCall(key: string, row?: UIEAgreement) {
		this.router.navigateToRoute(this.editUrl, { id: row?.id });
	}

	async search() {
		if (!this.privilege) {
			return;
		}
    const promiseList: Promise<EAgreement[]>[] = [];
    // * List agreements for BG also on lower level, similar to POS elements
    if (this.privilege.type == 'CLIENT') {
      const client = this.clients[this.privilege.id];
      if (client.businessGroupId) {
        promiseList.push(this.api.eAgreementList({ privilegeId: client.businessGroupId, privilegeType: "BUSINESS_GROUP" }));
      }
    }
		promiseList.push(this.api.eAgreementList({ privilegeId: this.privilege.id, privilegeType: this.privilege.type }));
    const resolvedResultList = await Promise.all(promiseList);
    this.itemList = resolvedResultList.flatMap(singleList => singleList.map(item => ({...item, deleted: !!item.deleteTime})))
	}

	@computedFrom("router.currentInstruction.config.navModel.config.name")
	get editUrl() {
		let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
		return routeName.replace(/list$/, "edit");
	}

	@computedFrom("privilege.id")
	get searchListener() {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		this.search();
		return "";
	}
}
